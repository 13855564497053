import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { getGames } from '../../api/getGames';
import { gameDefaultImagepng } from '../../images';
import Carousel from './Carousel';

function HomeGamesComponent({
  id,
  data,
  type,
  gotoGame,
}) {
  const [gameList, setGameList] = useState([]);
  const [userId, setUserId] = useState(Cookies.get('userId'));

  const fetchGames = async (id, brandIcons) => {
    // const result = await getGames({ brandId: id, branchId: data?.branchId, type });
    if (id) {
      const filterBrand = brandIcons.find((x) => x._id === id);
      filterBrand?.games?.map((x) => {
        x.brandName = filterBrand.name;
      });
      const filterType = filterBrand?.games?.filter((x) => x.type === type.toString());

      const findBrandIcon = brandIcons.find((x) => x.brandId === filterBrand._id);
      if (findBrandIcon) {
        filterType?.map((x) => x.brandThumbnail = findBrandIcon.imageUrl);
      }

      setGameList(filterType);
    } else {
      let result = [];
      // Replace icon from brand icons
      // Filter visible brand
      brandIcons?.map((x) => {
        if (x[`imageUrl_${type}`]) {
          result.push({
            _id: x.brandId,
            games: x.games, // all games show
            isVisible: x.isVisible,
            name: x.name,
            sorting: x.sorting,
            sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
            thumbnail: x[`imageUrl_${type}`],
          });
        }
      });

      const allgames = [];
      result = result.filter((i) => i.isVisible);

      result.forEach((brand) => {
        allgames.push(...brand.games.map((game) => ({
          ...game, brandThumbnail: brand.thumbnail, brandName: brand.name, brandId: brand._id,
        })));
      });

      if (type === 1) {
        allgames = allgames.sort((a, b) => {
          if (a.name === 'Aviator') return -1; // a should come first
          if (b.name === 'Aviator') return 1; // b should come first
          return 0; // leave other items unchanged
        });
      } else if (type === 3 || type === 4) {
        allgames = allgames.filter(
          (x) => x.type === type.toString() && (!x?.appDownloadUrl) && (x?.thumbnail) && (x?.gameId) && (x.status !== '1'),
        );
      } else {
        allgames = allgames.filter(
          (x) => x.type === type.toString() && (!x?.appDownloadUrl) && (x?.thumbnail) && (x?.gameId) && (x.status !== '1') && (x?.hotGame !== undefined),
        );
      }

      setGameList(allgames);
    }
  };

  useEffect(() => {
    fetchGames(id, data?.brandIcons);
  }, [id, data?.brandIcons]);

  return (
    <Carousel
      id={id || `${type}`}
      className='mt-2 mb-8'
      items={(
        <>
          {gameList
            // .filter((x, i) => i <= 13)
            .map((x, i) =>
            // console.log({ x });
              (
              // const backgroundImage = `url(${k.thumbnail})`;
                <div
                  className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap flex-item-game'
                  key={`${i}-${i._id}`}
                  onClick={() => {
                    const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

                    const existingIndex = currentArray.findIndex((item) => item._id === x._id);

                    let updatedArray;

                    if (existingIndex !== -1) {
                      updatedArray = [
                        ...currentArray.slice(0, existingIndex),
                        ...currentArray.slice(existingIndex + 1),
                        x,
                      ];
                    } else {
                      updatedArray = [...currentArray, x];
                    }

                    localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

                    gotoGame(x);
                  }}
                >
                  <div className='relative game-overlay-img mb-1.5 h-24 w-full s:h-32 sm:h-34 md:h-36 lg:h-38'>
                    <div className='game-overlay'>
                      <Button
                        style={{
                          background: '#28AE60',
                        }}
                        htmlType='submit'
                        className='flex bg-boxyellow rounded-full h-9 md:h-10 text-center items-center justify-center'
                      >
                        <p className='text-white font-medium text-xs md:text-sm'>
                          Play
                        </p>
                      </Button>
                    </div>
                    <div
                      id={`skeleton-id-${i}`}
                      className='loading skeleton-gray h-24 w-full s:h-32 sm:h-34 md:h-36 lg:h-38'
                    />
                    <img
                      className='hidden h-24 w-full object-cover s:h-32 sm:h-34 md:h-36 lg:h-38'
                      src={x.thumbnail || gameDefaultImagepng.src}
                      onLoad={(e) => {
                        setInterval(()=> {
                          document.getElementById(`skeleton-id-${i}`)?.remove();
                          e.target.classList?.remove('hidden');
                        }, 500)
                      }}
                      loading="lazy"
                      alt='Cricket betting sites in India'
                    />
                  </div>
        
                  <div className='bg-white flex items-center w-full h-7 md:h-8 flex justify-center game-overlay-text px-2 -mt-1'>
                    <div className='leading-none mr-0.5' style={{ width: 22 }}>
                      <img src={x.brandThumbnail} alt='' />
                    </div>
                    <p
                      className='leading-none text-xs font-semibold opacity-80 capitalize cut-text-game'
                    >
                      {x.brandName || x.name}
                    </p>
                  </div>
                </div>
              ))}
        </>
      )}
    />
  );
}

export default HomeGamesComponent;
