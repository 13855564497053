const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getGames = (query) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/v1/games`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        Cookies.get('accessToken')
      }`,
    },
    params: { ...query },
  })
  .then((response) => response)
  .catch((error) => error);

module.exports = {
  getGames,
};
